<template>
  <div class="animation" style="animation-delay: 2s;">
    <!-- view list -->
    <div v-if="show">
      <img
        v-for="project in projectsC"
        v-bind:src="
          'https://www.ofisdesigncms.actstudio.xyz' + project.cover.path
        "
        v-bind:key="project._id"
        v-bind:class="{ animation: projectIdSelected == project._id }"
        alt="Proyecto - Ofis Design"
        class="Projects__view-list--img-background opacity-0"
      />
      
      <div class="projects-categories">
        <template v-for="(project, pro) in projectsC">
          <span
            v-for="(category, cat2) in project.categories"
            v-bind:key="pro + '-' + cat2"
            v-show="projectIdSelected == project._id"
            class="Project--categories capitalize aeonik-regular"
            >{{ category.name }}</span
          >
        </template>
      </div>
    </div>
    <!-- /view list -->

    <transition name="fade-transition">
      <template v-if="show">
        <v-container fluid class="Projects__view-list position-relative">

          <!-- desk -->
          <v-row
            justify="center"
            class="mt-7 mt-md-2 not-visible-tablet-to-down"
          >
            <v-col
              v-for="(project, prs) in projectsC"
              v-bind:key="prs"
              cols="12"
              class="text-center px-0"
            >
              <router-link
                :to="'/project/' + project.url"
                class="not-text-decoration"
              >
                <div
                  v-html="project.name"
                  v-bind:class="{
                    selected: projectIdSelected == project._id,
                    'not-selected': projectIdSelected != null,
                  }"
                  class="capitalize aeonik-light titles-jumbo Projects__paragraphs--view-c toClick"
                  @mouseover="projectIdSelected = project._id"
                  @mouseout="projectIdSelected = null"
                ></div>
              </router-link>
            </v-col>
          </v-row>
          <!-- /desk -->

          <!-- tablet to down -->
          <v-row justify="center" class="mt-3 mt-sm-7 mt-md-2 visible-tablet-to-down">
            <v-col
              v-for="(project, prs) in projectsC"
              v-bind:key="prs"
              cols="12"
              class="text-center px-0"
            >
              <div
                v-html="project.name"
                v-bind:class="{
                  selected: projectIdSelected == project._id,
                  'not-selected': projectIdSelected != null,
                }"
                class="capitalize aeonik-light titles-jumbo Projects__paragraphs--view-c toClick"
                @click="gotoProject(project._id, project.url)"
              ></div>
            </v-col>
          </v-row>
          <!-- /tablet to down -->

        </v-container>
      </template>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ViewC",
  props: {
    show: { type: Boolean, required: true },
    projectsC: { type: Array, required: true },
  },
  data() {
    return {
      projectIdSelected: null,
    };
  },
  methods:{
    gotoProject: function(Id, url){//método solo para tablet y mobile
      this.projectIdSelected = Id;

      setTimeout(()=>{
        this.$router.push("/project/"+url);
      }, 2000);
    }
  }
};
</script>