<template>
  <transition name="fade-transition">
    <template v-if="show">
      <v-container
        class="px-8 px-sm-12 px-md-3 pt-0 pb-8 py-sm-12 view__b"
      >
        <v-row class="update-padding-viewB">
          <v-col
            v-for="(project, prs) in projectsC"
            v-bind:key="prs"
            v-bind:class="selectClass(prs)"
            cols="12"
            sm="4"
            class="px-xl-8"
          >
            <v-card
              tile
              elevation="0"
              :to="'/project/' + project.url"
              :ripple="false"
              class="toClick"
            >
              <v-img
                lazy-src
                :src="
                  'https://www.ofisdesigncms.actstudio.xyz' +
                  project.miniature.path
                "
                class="Projects--image-view-a"
              ></v-img>

              <v-card-text
                class="Project--line-height-texts pt-3 pt-xl-5 pb-0 pb-xl-8 px-0"
              >
                <v-row no-gutters>
                  <v-col>
                    <div
                      v-html="project.name"
                      class="capitalize Projects__paragraphs titles-card-projects rewrite-fontsize aeonik-bold color-black"
                    ></div>
                    <span
                      class="color-black subtitles-card-projects capitalize d-block"
                    >
                      {{ project.location }}
                    </span>
                    <template v-if="project.meters > 0">
                      <span class="color-black subtitles-card-projects">
                        {{ numberWithCommas(project.meters) }}m<sup>2</sup>
                      </span>
                    </template>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </transition>
</template>
<script>
export default {
  name: "ViewB",
  props: {
    show: { type: Boolean, required: true },
    projectsC: { type: Array, required: true },
  },
  methods: {
    numberWithCommas: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    selectClass: function (value) {
      if (value <= 9) {
        return "animation";
      } else {
        return "animation-lazy";
      }
    },
  },
};
</script>